import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import styled from 'styled-components';

export const ExportButton = styled.button`
  position: absolute;
  top: 85px;
  right: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  &:hover {
    background-color: #45a049;
  }

  &:focus {
    outline: none;
  }
`;

export const exportToPDF = async (
  elementId, 
  fitToPage = false, 
  fileName = 'document.pdf', 
  headerLeftText = '', 
  headerCenterText = '', 
  logoUrl = ''
) => {
  const input = document.getElementById(elementId);

  const pdf = new jsPDF('landscape', 'mm', 'a4');
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();
  const marginTop = 10; // 1cm de margem no topo

  const canvas = await html2canvas(input, { scale: 2 });
  const imgData = canvas.toDataURL('image/png');
  const imgWidth = pdfWidth;
  const imgHeight = (canvas.height * imgWidth) / canvas.width;

  // Obter data e hora no formato brasileiro
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

  // Função para adicionar o cabeçalho
  const addHeader = () => {
    pdf.setFontSize(15);
    const headerHeight = 10; // Espaço reservado para o cabeçalho

    // Adicionar texto alinhado à esquerda
    pdf.text(headerLeftText, 10, marginTop);

    // Adicionar texto centralizado
    const centerPosition = pdfWidth / 2 - pdf.getTextWidth(headerCenterText) / 2;
    pdf.text(headerCenterText, centerPosition, marginTop);

    // Adicionar logo à direita (se a URL for fornecida)
    if (logoUrl) {
      const logoWidth = 40;
      const logoHeight = 10;
      pdf.addImage(logoImg, 'PNG', pdfWidth - logoWidth - 10, marginTop - 5, logoWidth, logoHeight);
    }
  };

  // Carregar o logo apenas uma vez
  let logoImg;
  if (logoUrl) {
    logoImg = await loadImage(logoUrl);
  }

  // Adicionar conteúdo da primeira página
  addHeader();
  if (fitToPage) {
    pdf.addImage(imgData, 'PNG', 0, marginTop + 10, pdfWidth, pdfHeight - marginTop - 10);
  } else {
    let heightLeft = imgHeight;
    let position = marginTop + 10;

    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pdfHeight - marginTop - 10;

    while (heightLeft > 0) {
      pdf.addPage();
      addHeader(); // Repetir o cabeçalho em cada página
      position = marginTop + 10;
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pdfHeight - marginTop - 10;
    }
  }

  // Adicionar rodapé com data e hora (alinhado à direita)
  pdf.setFontSize(10);
  const footerPosition = pdfWidth - pdf.getTextWidth(formattedDate) - 10;
  pdf.text(formattedDate, footerPosition, pdfHeight - 10);

  pdf.save(fileName);
};

// Função para carregar a imagem do logo
const loadImage = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = url;
  });
};
