import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Menu from "components/Menu";

const Content = styled.div`
  display: flex;
  flex-flow: row nowrap;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
`;

const Main = styled.div`
  padding: 0;
  width: 100%;
  overflow: auto;
  z-index: 1;
  position: relative;
  background: #f8f8fb;
  padding-top: 70px;

  @media (max-width: 1280px) {
    padding: 70px 0 50px;
  }

  @media (max-width: 940px) {
    padding: 70px 0 0;
  }
`;

const FilterSidebar = styled.div`
  background: #089ca3;
  position: fixed;
  right: 0;
  top: 70px;
  height: calc(100vh - 70px);
  z-index: 4;
  padding: 50px 90px;
  width: 500px;
  transition: transform 0.7s ease-in;
  overflow: auto;
  ${(props) =>
    props.opened
      ? "transform: translateX(0px);"
      : "transform: translateX(500px);"}
`;

function InternalLayout({
  children,
  title,
  filters = null,
  modals = null,
  toogledSearch,
}) {
  const [openFilter, setOpenFilter] = useState(true);
  const [firstOpen, setFirstOpen] = useState(true);

  useEffect(() => {
    if (!firstOpen) {
      if (openFilter) {
        setOpenFilter(false);
      }
    } else {
      setFirstOpen(false);
    }
  }, [toogledSearch]);

  return (
    <Content>
      <Menu
        title={title}
        onClickFilter={() => {
          setOpenFilter(!openFilter);
        }}
      />
      <FilterSidebar opened={openFilter}>{filters}</FilterSidebar>
      <Main>{children}</Main>
      {modals}
    </Content>
  );
}

export default InternalLayout;
