import React, { useRef, useState, useEffect, useContext } from 'react';
import BoaConversa from "../../containers/BoaConversa";
import {
  bizGridResponsive,
  tamanhoDafonte,
  setarBgColor,
} from "../../utils/biz-padrao-visual";
import { BryntumGrid } from "@bryntum/grid-react";
import { RelativeIndicatorContext } from 'context/RelativeIndicator';
import { returnaAlturaTotalElementos, returnaTotalMedidasVerticais  } from "../../utils";


const Graph = ({ variant, children, graphData, graphHeader, usedSearchPayload, nomeCliente, nomePeriodoResposta, ...props}) => {
  const {
    frasesPalavrasRespondente, getFrasesPalavrasRespondente,
  } = useContext(RelativeIndicatorContext);
  const [respondente, setRespondente] = useState();
  const [currentdata, setCurrentData] = useState();
  const chartReferencia = useRef(null);
  const dadosGrafico = graphData || [];
  const larguraSuplica = 110;
  const dados = graphData;
  const header = graphHeader;

  const redirecionarBoaConversa = (data) => setRespondente(data);

  const formatarHeader = (valor) => {

    if (valor == "Conquiste a minha confiança!")
      return "CONQUISTE<BR />A MINHA<BR />CONFIANÇA!";

    return valor.replace(" ", "<br />");
  }

  useEffect(() => {
    if (!frasesPalavrasRespondente.loading && frasesPalavrasRespondente.length && currentdata) {
      redirecionarBoaConversa({ ...currentdata, ...frasesPalavrasRespondente[0] });
    }
  }, [frasesPalavrasRespondente])

  const gridInfo = {
    responsiveLevels: bizGridResponsive,
    readOnly: true,
    store: {
      tree: false,
      data: graphData,
    },
    features: {
      cellMenu: {
        disabled: true,
      },
      headerMenu: {
        disabled: true,
      },
    },
    columns: [
      {
        text: "RESPONDENTES",
        field: "icr_nome_respondente",
        flex: 1,
        minWidth: 300,
        autoHeight: true,
        htmlEncode: false,
        filterable: true,
        groupable: false,
        renderer: ({ value, cellElement, record }) => {
          const valores = (record.icr_nome_agente_holograma + "|" + record.icr_camadas.trim()).split("|");
          return `
          <div class="mb-2 mt-2 ml-1">
            <div class="font-size-16 text-bold">
              ${record.icr_nome_respondente}
            </div>
            <div class="font-size-12">
              ${record.icr_empresa_respondente || "-"}
            </div>
            <div>
            ${valores
              .map((valor, i) => {
                if (!valor) return "";
                return `
                <span class="badge bg-light mr-2 p-1">
                    ${valor}
                  </span>`;
              })
              .join(" ")}
            </div>
            
          </div>
          
          `;
        },
      },
      {
        text: dados.length > 0 ? header.nome_suplica_1 : "",
        field: "suplica_retorno_1",
        width: larguraSuplica,
        align: "center",
        htmlEncode: false,
        filterable: false,
        groupable: false,
        renderer({ value, cellElement, record }) {
          setarBgColor(cellElement, record.badge_1);
          return tamanhoDafonte(18, value);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.length > 0 ? header.nome_suplica_2 : "",
        field: "suplica_retorno_2",
        width: larguraSuplica,
        align: "center",
        htmlEncode: false,
        filterable: false,
        renderer({ value, cellElement, record }) {
          setarBgColor(cellElement, record.badge_2);
          return tamanhoDafonte(18, value);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.length > 0 ? header.nome_suplica_3 : "",
        field: "suplica_retorno_3",
        width: larguraSuplica,
        align: "center",
        htmlEncode: false,
        filterable: false,
        groupable: false,
        renderer({ value, cellElement, record }) {
          setarBgColor(cellElement, record.badge_3);
          return tamanhoDafonte(18, value);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.length > 0 ? header.nome_suplica_4 : "",
        field: "suplica_retorno_4",
        width: larguraSuplica,
        align: "center",
        htmlEncode: false,
        filterable: false,
        groupable: false,
        renderer({ value, cellElement, record }) {
          setarBgColor(cellElement, record.badge_4);
          return tamanhoDafonte(18, value);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.length > 0 ? header.nome_suplica_5 : "",
        field: "suplica_retorno_5",
        width: larguraSuplica,
        align: "center",
        htmlEncode: false,
        filterable: false,
        renderer({ value, cellElement, record }) {
          setarBgColor(cellElement, record.badge_5);
          return tamanhoDafonte(18, value);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.length > 0 ? header.nome_suplica_6 : "",
        field: "suplica_retorno_6",
        width: larguraSuplica,
        align: "center",
        htmlEncode: false,
        filterable: false,
        renderer({ value, cellElement, record }) {
          setarBgColor(cellElement, record.badge_6);
          return tamanhoDafonte(18, value);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.length > 0 ? header.nome_suplica_7 : "",
        field: "suplica_retorno_7",
        width: larguraSuplica,
        align: "center",
        htmlEncode: false,
        filterable: false,
        renderer({ value, cellElement, record }) {
          setarBgColor(cellElement, record.badge_7);
          return tamanhoDafonte(18, value);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: dados.length > 0 ? header.nome_suplica_8 : "",
        field: "suplica_retorno_8",
        width: larguraSuplica + 10,
        align: "center",
        htmlEncode: false,
        filterable: false,
        renderer({ value, cellElement, record }) {
          setarBgColor(cellElement, record.badge_8);
          return tamanhoDafonte(18, value);
        },
        headerRenderer({ column }) {
          return formatarHeader(column.text);
        }
      },
      {
        text: 'A boa conversa',
        width: 150,
        type: 'widget',
        align: "center",
        filterable: false,
        widgets: [{
          type: 'button',
          content: '<div>Ponto de <br />atenção</div>',
          cls: 'b-blue b-raised btn-boa-conversa py-1 w-100',
          onAction: ({ source: btn }) => {
            const data = {
              idParceiro: usedSearchPayload.idParceiro,
              idCliente: usedSearchPayload.idCliente,
              idPeriodoResposta: usedSearchPayload.idPeriodoResposta,
              idAgenteHolograma: btn.cellInfo.record.data.icr_id_agente_holograma,
              idRespondente: btn.cellInfo.record.data.icr_id_respondente,
            }

            setCurrentData(btn.cellInfo.record.data);
            getFrasesPalavrasRespondente(data);
          }
        }]
      }
    ],
  };

  let alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico", ".graficoprincipal .card"]) + 30;
  let alturaFinal = ""
  if (window.outerHeight < 600) {
      alturaGrafico = alturaGrafico * 4;
      alturaFinal = `calc(100vh)`;
  } else {
      alturaFinal = `calc(100vh - 132px)`;
  }

  return (
    <div>
      {respondente && (
        <BoaConversa
          nomeCliente={nomeCliente}
          nomePeriodoResposta={nomePeriodoResposta}
          respondente={respondente}
          suplicas={header}
          onClose={() => {
            setCurrentData();
            setRespondente();
          }}
        />
      )}
      <BryntumGrid
        {...gridInfo}
        treeFeature={false}
        columnReorderFeature={false}
        cellEditFeature={false}
        summaryFeature={false}
        filterBarFeature={{ compactMode: true }}
        cellMenuFeature={{
          disabled: true,
        }}
        headerMenuFeature={{
          items: {
            hideColumn: false,
          },
        }}
      />
    </div>
  );
};

export default React.memo(Graph);
