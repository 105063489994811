import React, { useState, useContext, useEffect, useRef } from 'react';
import { getUserData, getRefreshToken } from 'services/getUserData';
import styled from 'styled-components';
import InternalLayout from 'components/InternalLayout';
import Loading from 'components/Loading';
import Graph from './Graph';
import Filters from 'containers/Filters/AgentsAndCamadaNegocio';
import '@bryntum/grid/grid.stockholm.css';
import { ExportButton, exportToPDF } from '../pdfExporter/pdfExporter'; // Adicionando a importação

import { RelativeIndicatorContext } from 'context/RelativeIndicator';
import logo from '../capital-relacional.png';

const Content = styled.div`
  padding-right: 10px;
  > div {
    height: calc(100vh - 100px);
  }
`

function CultureIndicatorsPage() {
  const [user, setUser] = useState(null);
  const contentRef = useRef();

  useEffect(() => {
    getRefreshToken();
    setUser(getUserData());
  }, []);

  useEffect(() => {
    if (user) {
      if (!user.hasRelationalPositionPage) {
        window.location = '#/login';
      }
    }
  }, [user]);

  const {
    posicionamentoRelacional, getPosicionamentoRelacional,
  } = useContext(RelativeIndicatorContext);
  const [usedSearch, setUsedSearch] = useState();
  const [currentPayload, setCurrentPayload] = useState();
  const [agents, setAgents] = useState();
  const [nomeCliente, setNomeCliente] = useState("");
  const [nomePeriodoResposta, setNomePeriodoResposta] = useState("");
  const [filtrou, setFiltrou] = useState(false);

  return (
    <InternalLayout
      title="Posicionamento relacional"
      toogledSearch={usedSearch}
      filters={
        <Filters
          onSearch={(payload) => {
            setNomeCliente(payload.nomeCliente);
            setNomePeriodoResposta(payload.nomePeriodoResposta);
              getPosicionamentoRelacional({
              ...payload,
            });
            setCurrentPayload(payload);
            setUsedSearch(!usedSearch);
            setFiltrou(true);
          }}
          changeType={true}
          onAgents={(payload) => {
            setAgents(payload);
          }}
        />
      }
    >
      <Loading show={posicionamentoRelacional.loading} size="big" />
      {posicionamentoRelacional && posicionamentoRelacional.length > 0 && (
        <ExportButton onClick={() => exportToPDF('content', false, "posicionamento_relacional.pdf", nomeCliente + " - " + nomePeriodoResposta, "Posicionamento Relacional", logo)}>Exportar</ExportButton>
      )}
      <Content id="content" ref={contentRef}>
        {(posicionamentoRelacional.loading) ? (
          <p></p>
        ) : posicionamentoRelacional && !posicionamentoRelacional.length ? (
          (filtrou ? <p style={{margin: 20}}>Sem resultados para a busca</p> : <p></p>)
        ) : (
          <Graph
            graphData={posicionamentoRelacional}
            currentPayload={currentPayload}
            currentAgents={agents}
            user={user}
          />
        )}
      </Content>
    </InternalLayout>
  );
}

export default CultureIndicatorsPage;
