import React, { useState, useContext, useEffect, useRef } from "react";
import { getUserData, getRefreshToken } from "services/getUserData";
import styled from "styled-components";
import InternalLayout from "components/InternalLayout";
import Loading from "components/Loading";
import Graph from "./Graph";
import Filters from "containers/Filters/AgentsAndCamadaNegocio";
import "@bryntum/grid/grid.stockholm.css";
import { ExportButton, exportToPDF } from '../pdfExporter/pdfExporter';
import { RelativeIndicatorContext } from "context/RelativeIndicator";
import logo from '../capital-relacional.png';

const Content = styled.div`
  > div {
    height: calc(100vh - 70px);
  }
`;

const CenterAgents = styled.h4`
  text-align: center;
  padding-top: 10px;
`;

let camadas = {
  1: "Clientes",
  2: "Colaboradores",
  5: "Líderes",
  8: "Consumidores finais",
  9: "Franqueados",
  10: "Jarinas",
  11: "Jarininhas",
  12: "Lojistas",
  13: "Desembarques",
  14: "Cooperados Gestão Agro",
  15: "Cooperados Gestão Empresarial",
  16: "Cooperados Gestão Pessoal",
  17: "Supervisores",
  18: "Gerentes CA",
  19: "Gerentes Agência",
};

function ProminientSupplicationPage() {
  const cores = {
    success: "#34c38f",
    warning: "#f1b44c",
    danger: "#f46a6a",
    black: "#21212"
  };

  const [user, setUser] = useState(null);
  const contentRef = useRef();

  useEffect(() => {
    getRefreshToken();
    setUser(getUserData());
  }, []);

  useEffect(() => {
    if (user) {
      if (!user.hasProminentSupplication) {
        window.location = "#/login";
      }
    }
  }, [user]);

  const { prominientSupplication, getProminientSupplication } = useContext(
    RelativeIndicatorContext
  );
  const [usedSearch, setUsedSearch] = useState();
  const [camadasSelectionadas, setCamadasSelectionadas] = useState();
  const [nomeCliente, setNomeCliente] = useState("");
  const [nomePeriodoResposta, setNomePeriodoResposta] = useState("");
  const [filtrou, setFiltrou] = useState(false);

  return (
    <InternalLayout
      title="Súplicas em destaque"
      toogledSearch={usedSearch}
      filters={
        <Filters
          onSearch={(payload) => {
            setNomeCliente(payload.nomeCliente);
            setNomePeriodoResposta(payload.nomePeriodoResposta);

            getProminientSupplication(payload);
            setUsedSearch(!usedSearch);

            let agentesCamada = ["Todas as Camadas"];

            if (
              payload.idsAgentesHolograma &&
              payload.idsAgentesHolograma.length
            ) {
              agentesCamada = [];
              const selected = payload.idsAgentesHolograma.split("|;");
              selected.forEach((element) => {
                if (camadas[element]) {
                  agentesCamada.push(camadas[element]);
                }
              });
            }
            setCamadasSelectionadas(agentesCamada);
            setFiltrou(true);
          }}
          onAgents={(data) => {
            camadas = {};
            data.forEach((item) => {
              camadas[item.value] = item.label;
            });
          }}
        />
      }
    >
      <Loading show={prominientSupplication.loading} size="big" />
      {prominientSupplication && prominientSupplication.length && <ExportButton onClick={() => exportToPDF('content', false, "suplicas_em_destaque.pdf", nomeCliente + " - " + nomePeriodoResposta, "Súplicas em destaque", logo)}>Exportar</ExportButton>}
      <Content id="content" ref={contentRef}>
        {prominientSupplication.loading ? (
          <p></p>
        ) : prominientSupplication && (!prominientSupplication.length) ? (
          (filtrou ? <p style={{ margin: 20 }}>Sem resultados para a busca</p>  : <p></p>)
        ) : (
          (prominientSupplication && prominientSupplication.length ?
          <>
            {camadasSelectionadas && (
              <CenterAgents>
                {camadasSelectionadas.join(" - ")}
                {prominientSupplication.length &&
                prominientSupplication[0].relacao_icr?.length ? (
                  <span
                    style={{
                      color:
                        cores[
                          prominientSupplication[0].relacao_cor || "black"
                        ],
                    }}
                  >
                    {` (${prominientSupplication[0].relacao_icr})`}
                  </span>
                ) : (
                  ""
                )}
              </CenterAgents>
            )}
            <Graph graphData={prominientSupplication} />
          </>
          : <p></p>)
        )}
      </Content>
    </InternalLayout>
  );
}

export default ProminientSupplicationPage;
